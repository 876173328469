import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/manage/td/order/page`,
    method: 'get',
    params
  })
}

export function delAPI(id) {
  return http({
    url: `/manage/td/order/delete?id=${id}`,
    method: 'post',
    data: {}
  })
}

export function getDetailAPI(id) {
  return http({
    url: `/manage/td/order/detail`,
    method: 'get',
    params: { id }
  })
}

