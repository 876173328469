<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="所属商户">
        <SelectTdShop v-model="searchData.shopId" width="200px"></SelectTdShop>
      </el-form-item>
        <el-form-item label="支付方式">
        <el-select style="width: 200px" v-model="searchData.payType" placeholder="请选择" size="mini" clearable>
          <el-option label="微信" :value="1"> </el-option>
          <el-option label="支付宝" :value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单号">
        <el-input style="width: 200px" clearable v-model="searchData.orderNo" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="下单用户昵称">
        <el-input style="width: 200px" clearable v-model="searchData.nickname" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="商品名称">
        <el-input style="width: 200px" clearable v-model="searchData.productName" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tabs">
      <div :class="['item', searchData.status === -1 ? 'active' : '']" @click="handleClickTab(-1)">
        <el-badge>
          <span class="tab-name">全部</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 0 ? 'active' : '']" @click="handleClickTab(0)">
        <el-badge>
          <span class="tab-name">待支付</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 1 ? 'active' : '']" @click="handleClickTab(1)">
        <el-badge>
          <span class="tab-name">待核销</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 20 ? 'active' : '']" @click="handleClickTab(20)">
        <el-badge>
          <span class="tab-name">已核销</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 30 ? 'active' : '']" @click="handleClickTab(30)">
        <el-badge>
          <span class="tab-name">已退款</span>
        </el-badge>
      </div>
    </div>
    <el-table :data="list">
      <el-table-column prop="orderNo" label="订单号" width="width"> </el-table-column>
      <el-table-column prop="shopName" label="店铺名称" width="width" min-width="100px">
        <template slot-scope="{ row }">{{ row?.shop?.name }}</template>
      </el-table-column>
        <el-table-column prop="nickname" label="下单用户信息" width="width" min-width="150px">
          <template slot-scope="{ row }">
          <div class="user-box" v-if="row.user">
            <div class="row">
              <span>ID：</span>
              <el-link type="primary" @click="$router.push({ name: 'CustomerList', query: { id: row.user.id } })">{{ row.user.id }}</el-link>
            </div>
            <div class="row">
              <span>昵称：</span>
              <span>{{ row.user.nickname }}</span>
            </div>
            <div class="row">
              <span>手机号：</span>
              <span>{{ row.user.mobile }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" width="width" min-width="80px">
        <template slot-scope="{ row }">
          <!-- 0: 待支付 1：待核销 2：支付失败 3：取消支付 4：超时取消 20： 已核销 -->
          <el-tag v-if="row.status == 1" type="danger" size="small">{{ row.statusDsc }}</el-tag>
          <el-tag v-if="row.status == 3 || row.status == 4" type="danger" size="small">{{ row.statusDsc }}</el-tag>
          <el-tag v-if="row.status == 20" type="success" size="small">{{ row.statusDsc }}</el-tag>
          <el-tag v-if="row.status == 30" type="warning" size="small">{{ row.statusDsc }}</el-tag>
        </template> 
      </el-table-column>
      <el-table-column prop="nickname" label="商品信息" width="width" min-width="220px">
          <template slot-scope="{ row }">
            <div class="product-list">
      <div class="product-item" v-for="(product, index) in row.productList" :key="index">
        <div class="field">
          <span>商品名称：</span>
          <span>{{ product.name }}</span>
        </div>
          <div class="field">
          <span>商品价格：</span>
          <span>￥{{ product.price }}元</span>
        </div>
      </div>
    </div>
        </template>
      </el-table-column>
      <el-table-column prop="payMoney" label="订单金额" width="width" min-width="100px">
      </el-table-column>
      <el-table-column prop="payTypeDsc" label="支付方式" width="width" min-width="80px">
      </el-table-column>
      <el-table-column prop="payTime" label="订单支付时间" width="width" min-width="130px"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="width" min-width="130px"> </el-table-column>
      <el-table-column label="操作" width="100px" fixed="right">
        <template slot-scope="{ row }">
        <el-link style="margin: 0 10px" type="primary" :underline="false" @click="handleDetail(row)">详情 </el-link>
          <el-link type="primary" :underline="false" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" />
        <!-- 详情弹窗 -->
    <el-drawer size="45%" :visible.sync="show_detail" direction="rtl">
      <Detail :detail="detail"></Detail>
    </el-drawer>
  </div>
</template>

<script>
import { getListAPI, delAPI, getDetailAPI, changeStatusAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import SelectTdShop from '@/views/td/shop-list/select-tdshop.vue'
import Detail from './detail.vue'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { SelectTdShop, Detail},
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      show_update: false,
      show_detail: false,
      list: [],
      searchData: {
        page: 1,
        pagesize: 10,
        shopId: null, // 所属商户
        nickname: '',// 下单用户昵称
        orderNo: '',// 订单号
        productName: '',// 商品名称
        payType: null,// 支付方式
        status: -1
      },
      total: 0,
      changeStatusData: {
        id: '',
        status: null
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },

  activated() {
    this.getList()
  },
  mounted() {},

  methods: {
        // 详情
    async handleDetail({ id }) {
      this.detail = await getDetailAPI(id)
      this.show_detail = true
    },
      // 审核弹窗
    getStatus(row) {
      this.changeStatusData = {
        id: row.id
      }
      this.show_review = true
    },
    changeStatus() {
      changeStatusAPI(this.changeStatusData).then(() => {
        this.$message.success('提交成功')
        this.getList()
        this.show_review = false
      })
    },
    handleClickTab(val) {
      if (this.searchData.status == val) return
      this.searchData.status = val
      this.getList()
    },
    async getList() {
      let params = { ...this.searchData }
      if (params.status == -1) {
        params.status = null
      }
      const res = await getListAPI(params)
      this.list = res.records || []
      this.total = res.totalRow || 0
    },
    // del
    async handleDel({ id }) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 新增
    handleAdd() {
      this.show_update = true
      this.$nextTick(() => {
        this.formData = {
          name: '',
          icon: '',
          describe: ''
        }
        this.$refs.form.resetFields()
      })
    },
    // edit
    async handleEdit({ id }) {
      this.show_update = true
      this.$nextTick(async () => {
        this.formData = await getDetailAPI(id)
        this.$refs.form.resetFields()
      })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
    .product-list {
      margin-top: 10px;
    }
        .product-item {
      display: flex;
      margin-bottom: 5px;
    }
    .field {
      display: flex;
      align-items: center;
      width: calc(100% / 2 - 10px); /* 三个字段平均分配宽度 */
    }
    .user-box {
    .row {
      display: flex;
      span {
        &:nth-child(1) {
          width: 60px;
          text-align: right;
        }
        &:nth-child(2) {
          text-align: left;
        }
      }
    }
  }
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 600;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 70px !important;
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.icon-box /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 108px;
    height: 108px;
    line-height: 108px;
    text-align: center;
  }
  .avatar {
    width: 108px;
    height: 108px;
    display: block;
  }
}
</style>
