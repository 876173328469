<template>
  <div class="wrapper">
    <div class="userinfo">
      <el-image
        style="width: 70px; height: 70px; border-radius: 5px; margin-right: 10px"
        :src="detail.user.face"
        :preview-src-list="[detail.user.face]"
        fit="contain"
      >
        <div style="line-height: 70px; text-align: center; background: #f5f7fa" slot="error">无</div>
      </el-image>
      <div class="info">
        <div class="nickname">{{ detail.user.nickname }}</div>
        <div class="nickname">{{ detail.user.mobile }}</div>
        <div class="nickname">
          <el-tag v-if="detail.user.level == 10" type="primary" size="mini">普卡</el-tag>
          <el-tag v-if="detail.user.level == 20" type="primary" size="mini">银卡</el-tag>
          <el-tag v-if="detail.user.level == 30" type="primary" size="mini">金卡</el-tag>
          <el-tag v-if="detail.user.level == 40" type="primary" size="mini">黑卡</el-tag>
          <el-tag style="margin-left: 20px" v-if="detail.user.sex == 1" type="primary" size="mini">男</el-tag>
          <el-tag style="margin-left: 20px" v-if="detail.user.sex == 2" type="primary" size="mini">女</el-tag>
          <el-tag style="margin-left: 20px" v-if="detail.user.sex == 3" type="primary" size="mini">未知</el-tag>
        </div>
      </div>
    </div>
    <div class="content">
      <el-descriptions direction="vertical" :column="4" border>
        <el-descriptions-item label="订单号">{{ detail.orderNo }}</el-descriptions-item>
        <el-descriptions-item label="状态" :span="2">{{ detail.statusDsc }}</el-descriptions-item>
        <el-descriptions-item label="支付方式">{{ detail.payTypeDsc }} </el-descriptions-item>
        <el-descriptions-item label="订单状态">{{ detail.statusDsc }}</el-descriptions-item>
        <el-descriptions-item label="订单金额">{{ detail.payMoney }}</el-descriptions-item>
        <el-descriptions-item label="支付时间">{{ detail.payTime }}</el-descriptions-item>
      </el-descriptions>

      <el-descriptions direction="vertical" :column="3" border style="margin: 20px auto;" v-if="detail.shop">
      <el-descriptions-item label="店铺id">{{ detail.shop.id }}</el-descriptions-item>
      <el-descriptions-item label="店铺名称">{{ detail.shop.name }}</el-descriptions-item>
        <el-descriptions-item label="店铺分类">{{ detail.shop.categoryName }}</el-descriptions-item>
        <el-descriptions-item label="联系方式">{{ detail.shop.contact }}</el-descriptions-item>
        <el-descriptions-item label="店铺地址">{{ detail.shop.address }}</el-descriptions-item>
      </el-descriptions>

      <el-descriptions direction="vertical" :column="4" border v-for="(item,index) in detail.productList" :key="index">
        <el-descriptions-item label="商品id">{{ item.productId }}</el-descriptions-item>
        <el-descriptions-item label="商品价格">{{ item.price }}</el-descriptions-item>
        <el-descriptions-item label="商品名称">{{ item.name }} </el-descriptions-item>
        <el-descriptions-item label="商品数量">{{ item.num }}</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Detail',
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>
<style lang="scss" scoped>
.wrapper {
  .userinfo {
    display: flex;
    padding: 0 20px;
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
    }
  }
  .content {
    padding: 20px;
  }
}
</style>
